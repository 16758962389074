import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
// import Logo from "./Logo";

export const Nav = () => {
  const [isNavIndex, setNavIndex] = React.useState("none");

  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: frontmatter___order }) {
        nodes {
          frontmatter {
            tags
            title
            status
          }
          fields {
            slug
          }
        }
      }
    }
  `);

  return (
    <div className="site-header-track">
      <header className="site-header">
        <Link to="/" activeClassName="active">
          <div className={`feature feature--side nav-${isNavIndex}`}>
            <svg className="feature__svg" viewBox="0 0 370 242">
              {data.allMdx.nodes.map((item, index) => (
                <use
                  key={index}
                  className={
                    isNavIndex === index
                      ? `feature__image feature__image-${index} active`
                      : `feature__image feature__image-${index}`
                  }
                  xlinkHref={"/shd-logo-colour.svg#svg-logo"}
                />
              ))}
              <use
                className={
                  isNavIndex === 5
                    ? `feature__image feature__image-5 active`
                    : `feature__image feature__image-5`
                }
                xlinkHref={"/shd-logo-colour.svg#svg-logo"}
              />
              <use
                className="feature__image-main"
                xlinkHref={"/shd-logo-colour.svg#svg-logo"}
              />
            </svg>
          </div>
        </Link>
        <nav>
          <ul>
            {data.allMdx.nodes.map(({ frontmatter, fields }, index) => {
              return (
                <li
                  key={index}
                  style={
                    frontmatter.status === "draft" ? { display: "none" } : {}
                  }
                >
                  <Link
                    to={fields.slug}
                    className={`navlink-${index}`}
                    activeClassName="active"
                    onMouseEnter={() => setNavIndex(index)}
                    onMouseLeave={() => setNavIndex("none")}
                  >
                    {frontmatter.title}

                    <ul className="tag-list">
                      {frontmatter.tags.map((tag, index) => (
                        <li key={index}>{tag}</li>
                      ))}
                    </ul>
                  </Link>
                </li>
              );
            })}
            <li>
              <a
                className="navlink-5"
                href="mailto:simon@simonhowe.dev"
                onMouseEnter={() => setNavIndex(5)}
                onMouseLeave={() => setNavIndex("none")}
              >
                Get in touch: simon@simonhowe.dev
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};
