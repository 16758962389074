import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Img from "gatsby-image";

import { Layout } from "../components/Layout";
// import image1400 from "../images/template-1400.png";
// import image360 from "../images/template-360.png";

export default function PortfolioPost({ data }) {
  const { frontmatter, body } = data.mdx;
  const [isFrameMobile, setFrameMobile] = React.useState(false);
  let image360 = frontmatter.image360.childImageSharp.fluid;
  let image1400 = frontmatter.image1400.childImageSharp.fluid;

  return (
    <Layout>
      <>
        <div
          className={
            isFrameMobile ? "frame frame--mobile" : "frame frame--desktop"
          }
        >
          <div className="frame__device">
            <div
              className="frame__aspect"
              // onClick={() => setFrameMobile(!isFrameMobile)}
            >
              <div className="frame__img frame__img--1400">
                <Img fluid={image1400} />
              </div>
              <div className="frame__img frame__img--360">
                <Img fluid={image360} />
              </div>
            </div>
          </div>
          <div className="frame__toggle">
            <label className="frame__toggle-label">
              Desktop
              <input
                type="checkbox"
                checked={isFrameMobile}
                onChange={() => setFrameMobile(!isFrameMobile)}
              />
              <span></span>
              Mobile
            </label>
          </div>
        </div>
        <h1>{frontmatter.title}</h1>

        <MDXRenderer>{body}</MDXRenderer>
        <hr />
        <p className="tag-list">
          Skills:{" "}
          {frontmatter.tags.map((tag, index) => (
            <span key={index}>{tag}</span>
          ))}
        </p>
        <p>
          <a
            className="external-link"
            href={frontmatter.link}
            target="_blank"
            rel="noreferrer"
          >
            View online
          </a>
        </p>
        <hr />
      </>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tags
        link
        image360 {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image1400 {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`;
