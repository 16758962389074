import React from "react";

import "@fontsource/source-sans-pro";
import "@fontsource/podkova";
import "../styles.scss";

import { Nav } from "./Nav";

export const Layout = ({ children }) => {
  return (
    <div className="site">
      <main className="site-main">{children}</main>
      <Nav />
    </div>
  );
};
